<template>
  <Html :class="`theme-${config.public['theme']}`">
    <Body>
      <slot />
    </Body>
  </Html>
</template>

<script setup>
const config = useRuntimeConfig();
</script>
